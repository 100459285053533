import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Loader, Menu, Dropdown } from 'semantic-ui-react';
import { HiOutlineLogin, HiOutlineLogout } from 'react-icons/hi';

import { Avatar } from 'components/Avatar';
import style from './Profile.module.scss';

import { useGlobalContext, useSocketContext, useAttendanceContext } from 'contexts';

import { CONTACT_SUPPORT_URL, EVENT_TYPE } from 'utils/constants';
import { checkInOut } from 'utils/checkInCheckOut';

export default function Profile() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [globalState, globalRequest, globalActions, globalDispatch] = useGlobalContext();
  const [, attendanceRequest] = useAttendanceContext();
  const [, socketActions, socketDispatch] = useSocketContext();
  const initialState = { admin: false };
  const [data, setData] = useState<any>(initialState);

  const { getLastUserEventById } = attendanceRequest;
  const { user, lastUserEvent } = globalState;
  const { createUserEvent } = globalRequest;
  const isCheckIn = lastUserEvent.type === EVENT_TYPE.CHECK_IN;
  const { isAdmin } = globalRequest;

  const avatarProps = {
    src: user.picture,
    name: user.preferredUsername,
    size: 40,
    isCheckIn
  };

  const load = useCallback(async () => {
    if (!user._id) return;
    const result = await getLastUserEventById(user._id);
    const admin = await isAdmin();
    setData({ admin });
    globalDispatch(globalActions.UPDATE_STATE({ lastUserEvent: result }));
  }, [globalDispatch, globalActions, getLastUserEventById, user, isAdmin]);

  useEffect(() => {
    load();
  }, [user]); // eslint-disable-line

  function CheckInOutEvent({ isLoading, onClick }: any): JSX.Element {
    let icon = <HiOutlineLogout size={20} className={`${style.icon} ${style.checkInIcon}`} />;
    const labelClassName = isCheckIn ? style.checkOutLabel : style.checkInLabel;
    const label = isCheckIn ? 'Check out' : 'Check in';

    if (isCheckIn) {
      icon = <HiOutlineLogin size={20} className={`${style.icon} ${style.checkOutIcon}`} />;
    }

    return (
      <Dropdown.Item className={style.checkInOut} onClick={onClick} disabled={isLoading}>
        <div className={style.ciCoButton}>
          {icon}
          <span className={labelClassName}>{label}</span>
          {isLoading && <Loader active inline="centered" size="mini" />}
        </div>
      </Dropdown.Item>
    );
  }

  async function clickCICOButton() {
    setLoading(true);
    await checkInOut(
      isCheckIn,
      createUserEvent,
      socketDispatch,
      socketActions,
      globalDispatch,
      globalActions,
    );
    setLoading(false);
  }

  return (
    <Dropdown className={style.avatar} as={Menu.Item} floating icon={<Avatar {...avatarProps} />}>
      <Dropdown.Menu direction="left">
        <CheckInOutEvent isLoading={loading} onClick={clickCICOButton} />
        { data.admin === true ? (<Dropdown.Item onClick={() => history.push('/settings/notification')}>Settings</Dropdown.Item>) : null}
        <Dropdown.Item onClick={() => window.open(CONTACT_SUPPORT_URL, '_blank')}>Support</Dropdown.Item>
        <Dropdown.Item onClick={() => history.push('/logout')}>Log out</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}
